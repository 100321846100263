<template>
  <v-app id="inspire">
    <v-navigation-drawer width="300" v-model="$store.state.drawer" app>
      <leftMenu />
      <listMenu />
    </v-navigation-drawer>
    
    <v-app-bar app elevation="0" color="#252C32" dark>
      <v-app-bar-nav-icon
        @click="$store.state.drawer = !$store.state.drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $store.state.mainTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- <b v-if="this.$route.params.id_house" class="mr-10 text-h4">PROFIT: {{ (this.$store.state.totalIngresos - this.$store.state.totalEgresos).toFixed(2) }} USD</b> -->

      <!-- <v-btn
        @click="$store.state.modalvelocidad = !$store.state.modalvelocidad"
        color="success"
        rounded
        elevation="0"
        >Test de velocidad</v-btn
      > -->
    </v-app-bar>

    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import leftMenu from "@/components/leftMenu";
import listMenu from "@/components/listMenu";
export default {
  components: {
    leftMenu,
    listMenu,
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 10px !important;
  background: #f4f7fa;
}
</style>
