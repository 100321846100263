<template>
  <div class="_container__menu">
    <div v-for="(items, index) of itemsModule" :key="index">
      <router-link
        to=""
        class="_module__menu"
        @click.native="_setidMenu(items.url)"
      >
        <v-icon dark large>{{ items.icon }}</v-icon>
        <p>{{ items.name }}</p>
        <div class="_divider"></div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "leftMenu",
  data() {
    return {
      itemsModule: [],
    };
  },
  mounted() {
    this._getEntitiesModule();
    this._getGroupList();
  },
  computed: {},
  methods: {
    ...mapActions(["_getGroupList"]),

    _getEntitiesModule() {
      var vm = this;
      let items = [
        {
          id: "5",
          description: "Pre cotizacionname",
          name: "Pre cotizacion",
          icon: "mdi-forum",
          url: "/pre_cotizacion",
          statusModule: 1,
        },
        {
          id: "3",
          description: "Cotización",
          name: "Cotización",
          icon: "mdi-cash-multiple",
          url: "/listado",
          statusModule: 1,
        },
        {
          id: "2",
          description: "Reporte",
          name: "Reporte",
          icon: "mdi-forum",
          url: "/reporte",
          statusModule: 1,
        },
        {
          id: "1",
          description: "Ficheros",
          name: "Ficheros",
          icon: "mdi-forum",
          url: "/ficheros",
          statusModule: 1,
        },
        { name: "Casilleros", icon: "mdi-forum", url: "/listado_casillero" },
        {
          id: "6",
          description: "Calculadora",
          name: "Calculadora",
          icon: "mdi-forum",
          url: "/carga_masiva",
          statusModule: 1,
        },
      ];
      vm.itemsModule = items;
    },

    _setidMenu(ruta) {
      this.$router.push(ruta);
      // this._getGroupList();
    },
  },
};
</script>

<style lang="scss" scoped>
._container__menu {
  text-align: center;
  width: 60px;
  height: 100vh;
  background: #313d48;
  padding: 15px 0px;
  float: left;

  ._module__menu {
    text-decoration: none;
    cursor: pointer;

    p {
      color: white;
      font-size: 12px;

      @media (max-width: 800px) {
        font-size: 12px;
      }
    }

    ._divider {
      height: 2px;
      border-radius: 25px;
      width: 80%;
      margin: auto;
      background: #98a5b1;
      margin-bottom: 10px;
    }
  }
}
</style>
